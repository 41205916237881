import { useEffect, useState } from "react";
import Button from "../components/Button";

import DiscPlayer from "../components/discPlayer/DiscPlayer";
import Dress from "../components/dress/Dress";
import Suit from "../components/suit/Suit";
import styles from "../styles/PartyDetails.module.css";
import ModalWindowDress from "../components/ModalWindowDress";
import data from "../data.json";
import { GiConfirmed, GiChecklist } from "react-icons/gi";
import { RiPlayList2Fill } from "react-icons/ri";
import { FcPlanner } from "react-icons/fc";

function PartyDetails(filteredItems) {
  const [data, setCode] = useState(filteredItems.filteredItems[0]);


  return (
    <section className={styles.partyDetailsContainer}>
      {data === undefined ? (
        <>
          <div className={styles.textContainer}>
            <h3 className={styles.h1}>
              No andábamos buscándonos, pero andábamos <br /> para encontrarnos
            </h3>
            <div className={styles.comillas}>- Julio Cortázar</div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.textContainer2}>
            <p className={styles.h5}>
              Esta invitación es para las siguientes personas: <br />
              <br /></p>
              <p className={styles.h2}>
              <strong>
                {data?.Personas.length === 2
                  ? data?.Personas.join(" y ")
                  : data?.Personas.join(", ").replace(/, ([^,]*)$/, ' y $1')}
              </strong></p>
            
            <br />
            <br />
            <div className={styles['text-container']}>
              <span className={styles["first-part"]}>
                Para nosotros es importante contar con tu presencia el día de nuestra boda,
                agradeceríamos <strong>confirmar tu asistencia hasta el jueves 22 de marzo.</strong><br /> Una vez realizada tu confirmación te estaremos enviando nuestra parte físico.
              </span>
            </div>
          </div>
          <br />
          <div className={styles.linksContainer}>
          <a
              className={styles.ahref}
              rel="noreferrer"
              target="_blank"
              href="https://bit.ly/ConfirmarAsistenciaByG"
            >
              <p className={styles.link}>
              &#9998; Confirmar asistencia
              </p>
            </a>
            <br />
            <br />
            <br />
            <br />
            <br />
            <span  className={styles.link2}>Información adicional: </span>
            <a
              target="_blank"
              rel="noreferrer"
              className={styles.ahref}
              href="https://bit.ly/AgregaTuCancionByG"
            >
              <p className={styles.link}>
                <RiPlayList2Fill /> Añade tus canciones en nuestra playlist. &#128540;
              </p>
            </a>
            <a
              className={styles.ahref}
              rel="noreferrer"
              target="_blank"
              href="https://bit.ly/ListaBodaByG"
            >
              <p className={styles.link}>
                <GiChecklist /> Visita nuestra lista de regalos &#127873; &#127881;
              </p>
            </a>
          </div>
        </>
      )}
    </section>
  );
}

export default PartyDetails;
