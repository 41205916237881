import clockFrame from "../assets/img/marcoDelicado.png";
import { RiDoubleQuotesL } from "react-icons/ri";
import { RiDoubleQuotesR } from "react-icons/ri";
import styles from "../styles/CountDown.module.css";
import { useState } from "react";
import moment from "moment";
import { AddToCalendarButton } from 'add-to-calendar-button-react';


function CountDown() {

  return (
    <section className={styles.container}>
    <div>
    <h2 className={styles.h2}>¡Nos casamos!</h2>
    <h1 className={styles.h1}>Reserva la fecha</h1><br />
      
      <h3 className={styles.h3}>Proximamente enviaremos muchos más detalles en la invitación de la boda.</h3>
      <br />
      <br />
      <h3 className={styles.h3}><strong>Bryan & Geraldine</strong>  |  <strong>Parroquia Santa Beatriz 4:00 pm</strong></h3>

    </div>
  </section>
  
  );
}

export default CountDown;
