import styles from "../styles/Home.module.css";
import Dress from "../components/dress/Dress";
import FrontPage from "../sections/FrontPage";
import CountDown from "../sections/CountDown";
import Schedule from "../sections/Schedule";
import Photos from "../sections/Photos";
import PartyDetails from "../sections/PartyDetails";
import Gifts from "../sections/Gifts";
import LastPage from "../sections/LastPage";
import Footer from "../sections/Footer";
import Final from "../sections/Final";
import { useParams } from "react-router-dom";
import { useState } from "react";
import axios from 'axios';

function Home() {
  let { id } = useParams();
  const data = {
    "data": [
      {
        "Codigo": "645925",
        "Pases": 3,
        "Personas": ["Santos Vislao Santacruz", "Bilma Chavez Teodocia", "Keyra Vislao Chavez"]
      },
      {
        "Codigo": "167076",
        "Pases": 4,
        "Personas": ["Bertha Lozano", "Hipólito Gonzales", "Hilbert Gonzales","Gustavo Gonzales"]
      },
      {
        "Codigo": "959536",
        "Pases": 1,
        "Personas": ["Victor Araujo"]
      },
      {
        "Codigo": "498837",
        "Pases": 3,
        "Personas": ["Susana Vargas", "Marco Gamarra","Diego Gamarra"]
      },
      {
        "Codigo": "558062",
        "Pases": 1,
        "Personas": ["Rafael Bazalar"]
      },
      {
        "Codigo": "820040",
        "Pases": 1,
        "Personas": ["Giselle Bazalar"]
      },
      {
        "Codigo": "213056",
        "Pases": 1,
        "Personas": ["Celmira Vargas"]
      },
      {
        "Codigo": "974148",
        "Pases": 2,
        "Personas": ["Roberto Valladares", "Claudia Bermudez"]
      },
      {
        "Codigo": "729575",
        "Pases": 2,
        "Personas": ["Carmela Lozano", "Alex Farfan"]
      },
      {
        "Codigo": "906168",
        "Pases": 3,
        "Personas": ["Mery Lozano", "Joseph Bolaños", "Abuelita Catalina"]
      },
      {
        "Codigo": "540622",
        "Pases": 1,
        "Personas": ["Rosalina Laureano"]
      },
      {
        "Codigo": "401761",
        "Pases": 1,
        "Personas": ["Diana Laureano"]
      },
      {
        "Codigo": "504632",
        "Pases": 2,
        "Personas": ["Alfredo Hinostroza", "Elizabeth Arapa"]
      },
      {
        "Codigo": "101456",
        "Pases": 1,
        "Personas": ["Isabel Huamani"]
      },
      {
        "Codigo": "427343",
        "Pases": 1,
        "Personas": ["Victor Pacheco"]
      },
      {
        "Codigo": "510954",
        "Pases": 2,
        "Personas": ["Rolando Angeles","Susana Chirinos"]
      },
      {
        "Codigo": "814196",
        "Pases": 1,
        "Personas": ["Felicita Ccama"]
      },
      {
        "Codigo": "622721",
        "Pases": 4,
        "Personas": ["Alex Arapa", "Amelia Arapa", "Luana Arapa", "Nicol Arapa"]
      },
      {
        "Codigo": "183512",
        "Pases": 3,
        "Personas": ["Ivan Arapa","Beatriz Guillen", " Sebastian Ian Arapa"]
      },
      {
        "Codigo": "836334",
        "Pases": 3,
        "Personas": ["Paul Arapa", "Pilar Wherrems", "Luis Enrique Arapa"]
      },
      {
        "Codigo": "183954",
        "Pases": 2,
        "Personas": ["Emiliano Cirilo y pareja"]
      },
      {
        "Codigo": "225653",
        "Pases": 2,
        "Personas": ["Alessandra Arapa", "Raul Pastor"]
      },
      {
        "Codigo": "289039",
        "Pases": 2,
        "Personas": ["Nicolas Arapa y Juana Cordova"]
      },
      {
        "Codigo": "399148",
        "Pases": 1,
        "Personas": ["Brigida Espinoza"]
      },
      {
        "Codigo": "314833",
        "Pases": 2,
        "Personas": ["Cirilo Arapa y Victoria Espinoza"]
      },
      {
        "Codigo": "949050",
        "Pases": 2,
        "Personas": ["Maria Ccama","Viviana Caballero"]
      },
      {
        "Codigo": "779268",
        "Pases": 4,
        "Personas": ["Teresa Vilca","Maribel Hinostroza","Gustavo Hinostroza","Raquel Hinostroza"]
      },
      {
        "Codigo": "899117",
        "Pases": 3,
        "Personas": ["Orlando Hinostroza", "Sofia Hidalgo", "Rafaela Hinostroza"]
      },
      {
        "Codigo": "242179",
        "Pases": 1,
        "Personas": ["Hector Hinostroza"]
      },
      {
        "Codigo": "186427",
        "Pases": 4,
        "Personas": ["Liliana Hinostroza","Augusto Liceta", "Alipio Hinostroza","Fortunata Pomahuali"]
      },
      {
        "Codigo": "976945",
        "Pases": 1,
        "Personas": ["Veronica Liceta"]
      },
      {
        "Codigo": "144664",
        "Pases": 1,
        "Personas": ["Eduardo Hinostroza"]
      },
      {
        "Codigo": "356866",
        "Pases": 1,
        "Personas": ["Gabriel Hinostroza"]
      },
      {
        "Codigo": "686964",
        "Pases": 2,
        "Personas": ["Claudia Hinostroza", "Ricky Quispe"]
      },
      {
        "Codigo": "861385",
        "Pases": 1,
        "Personas": ["Gianella Durand"]
      },
      {
        "Codigo": "767290",
        "Pases": 2,
        "Personas": ["Avril Arapa", "Paolo Cochachi"]
      },
      {
        "Codigo": "533672",
        "Pases": 1,
        "Personas": ["Luis Alfonzo Chavez"]
      },
      {
        "Codigo": "969222",
        "Pases": 2,
        "Personas": ["Christian Chuco y Barbara Silva"]
      },
      {
        "Codigo": "961402",
        "Pases": 2,
        "Personas": ["Armando Tomaylla","Katherine Cordova"]
      },
      {
        "Codigo": "477147",
        "Pases": 2,
        "Personas": ["Jhordi Cirilo y esposa"]
      },
      {
        "Codigo": "862372",
        "Pases": 1,
        "Personas": ["Bryan Naveda"]
      },
      {
        "Codigo": "728147",
        "Pases": 2,
        "Personas": ["Edgar Rodriguez", "Diana Campos"]
      },
      {
        "Codigo": "604128",
        "Pases": 2,
        "Personas": ["Ronny Sacha","Stefanny Ayala"]
      },
      {
        "Codigo": "428741",
        "Pases": 1,
        "Personas": ["Diana Estrella"]
      },
      {
        "Codigo": "150967",
        "Pases": 1,
        "Personas": ["Sandro Paredes"]
      },
      {
        "Codigo": "314175",
        "Pases": 2,
        "Personas": ["Renzo Yallico","Beatriz Bolivar"]
      },
      {
        "Codigo": "272209",
        "Pases": 2,
        "Personas": ["Davis Buitron", "esposa"]
      },
      {
        "Codigo": "326225",
        "Pases": 1,
        "Personas": ["Jimmy Panta"]
      },
      {
        "Codigo": "794607",
        "Pases": 2,
        "Personas": ["Franco Rojas","esposa"]
      },
      {
        "Codigo": "811956",
        "Pases": 1,
        "Personas": ["Eduardo Santos"]
      },
      {
        "Codigo": "960400",
        "Pases": 1,
        "Personas": ["Ruben Sanchez"]
      },
      {
        "Codigo": "675687",
        "Pases": 2,
        "Personas": ["Zenon Paredes", "esposa"]
      },
      {
        "Codigo": "196012",
        "Pases": 1,
        "Personas": ["Bryan Arenas"]
      },
      {
        "Codigo": "101221",
        "Pases": 1,
        "Personas": ["Rafael Durand"]
      },
      {
        "Codigo": "579152",
        "Pases": 1,
        "Personas": ["Giancarlo Huachin"]
      },
      {
        "Codigo": "354385",
        "Pases": 2,
        "Personas": ["Juan Pablo Rodriguez", "esposa"]
      },
      {
        "Codigo": "152412",
        "Pases": 1,
        "Personas": ["Daniel Seclen"]
      },
      {
        "Codigo": "456934",
        "Pases": 1,
        "Personas": ["Miguel Ikhehara"]
      },
      {
        "Codigo": "296784",
        "Pases": 1,
        "Personas": ["Richard Perez"]
      },
      {
        "Codigo": "723236",
        "Pases": 1,
        "Personas": ["Alexander Cairampoma"]
      },
      {
        "Codigo": "290484",
        "Pases": 1,
        "Personas": ["Elita Tarrillo"]
      },
      {
        "Codigo": "278703",
        "Pases": 1,
        "Personas": ["Maria Elena Rivera"]
      },
      {
        "Codigo": "117417",
        "Pases": 1,
        "Personas": ["Susana Trillo"]
      },
      {
        "Codigo": "643307",
        "Pases": 1,
        "Personas": ["Oscar Gonzales"]
      },
      {
        "Codigo": "300419",
        "Pases": 2,
        "Personas": ["Jorge Villar", "esposa"]
      },
      {
        "Codigo": "400543",
        "Pases": 2,
        "Personas": ["Elena Silva", "Jean Espinoza"]
      },
      {
        "Codigo": "750538",
        "Pases": 2,
        "Personas": ["Jose Luis Velasquez", "esposa"]
      },
      {
        "Codigo": "669698",
        "Pases": 2,
        "Personas": ["Pablo Saravia", "esposa"]
      },
      {
        "Codigo": "593002",
        "Pases": 2,
        "Personas": ["Pilar Paredes", "Edson Pando"]
      },
      {
        "Codigo": "821177",
        "Pases": 1,
        "Personas": ["Yaycce Mendoza"]
      },
      {
        "Codigo": "239854",
        "Pases": 2,
        "Personas": ["Jose Luis Ayala", "esposa"]
      },
      {
        "Codigo": "686891",
        "Pases": 2,
        "Personas": ["Frank Lopez", "Maria Hidalgo"]
      },
      {
        "Codigo": "679453",
        "Pases": 1,
        "Personas": ["Ricardo Ramirez"]
      },
      {
        "Codigo": "912387",
        "Pases": 1,
        "Personas": ["Issac Neira"]
      },
      {
        "Codigo": "384087",
        "Pases": 2,
        "Personas": ["Jean Paul Mejia", "Fiorella Diaz"]
      },
      {
        "Codigo": "218515",
        "Pases": 1,
        "Personas": ["Celinda Carrasco"]
      },
      {
        "Codigo": "452811",
        "Pases": 2,
        "Personas": ["Sergio Escobar","Tatiana Simbala"]
      },
      {
        "Codigo": "308317",
        "Pases": 1,
        "Personas": ["Yuriko Huamanchumo"]
      },
      {
        "Codigo": "900356",
        "Pases": 2,
        "Personas": ["Stephany Castro", "Dhalin Aguilar"]
      },
      {
        "Codigo": "991420",
        "Pases": 2,
        "Personas": ["Daniel Angeles", "Dorcas Mozombite"]
      },
      {
        "Codigo": "831727",
        "Pases": 2,
        "Personas": ["Claudia Sarmiento", "Pareja"]
      },
      {
        "Codigo": "752177",
        "Pases": 1,
        "Personas": ["Melissa Torres"]
      },
      {
        "Codigo": "752071",
        "Pases": 1,
        "Personas": ["Reyna Gonzales"]
      },
      {
        "Codigo": "359092",
        "Pases": 1,
        "Personas": ["Ingrith Moreno"]
      },
      {
        "Codigo": "312841",
        "Pases": 1,
        "Personas": ["Rut Briceño","esposo"]
      },
      {
        "Codigo": "578440",
        "Pases": 1,
        "Personas": ["Angie Jimenez"]
      },
      {
        "Codigo": "287486",
        "Pases": 1,
        "Personas": ["Rosa Bravo"]
      },
      {
        "Codigo": "492614",
        "Pases": 2,
        "Personas": ["Gabriela Gonzales", "esposo"]
      },
      {
        "Codigo": "966238",
        "Pases": 1,
        "Personas": ["Sandra Galarreta"]
      },
      {
        "Codigo": "938440",
        "Pases": 1,
        "Personas": ["Miss Matilde"]
      },{
        "Codigo": "212587",
        "Pases": 2,
        "Personas": ["Priscila Hinostroza","esposo"]
      },{
        "Codigo": "528776",
        "Pases": 1,
        "Personas": ["Claudia Cruz"]
      }
      ,{
        "Codigo": "200033",
        "Pases": 2,
        "Personas": ["Luis Miranda y pareja"]
      },{
        "Codigo": "135150",
        "Pases": 1,
        "Personas": ["Gerzon Rodriguez"]
      }
      ,{
        "Codigo": "552493",
        "Pases": 1,
        "Personas": ["Rosario Rojas"]
      },{
        "Codigo": "424360",
        "Pases": 1,
        "Personas": ["Ines Espinoza"]
      },{
        "Codigo": "814737",
        "Pases": 1,
        "Personas": ["Ana Farfan"]
      },{
        "Codigo": "618991",
        "Pases": 1,
        "Personas": ["Pilar Quispe"]
      },
      {
        "Codigo": "239269",
        "Pases": 1,
        "Personas": ["Miguel Llontop"]
      }

    ]
  }

  const [code,setCode] = useState(id ?? 0);
  const [dataCodes,setDataCodes] = useState(data.data.filter(x=> x.Codigo ===  code));
  const [filteredItems, setFilteredItems] = useState(dataCodes ?? null);

  return (
    <div className={styles.portada}>
      <div className={styles.parallax}></div>
      <br />
      <FrontPage />
      <CountDown />
      <hr className="" />
      <PartyDetails filteredItems={filteredItems} />
      {/* <div className={styles.parallax3}></div> */}
     <Final />
      <Footer />
    </div>
  );
}

export default Home;
