import './dress.css'

function Dress() {
  return (
<svg height="100" viewBox="0 0 506 800" fill="none" xmlns="http://www.w3.org/2000/svg">
<path id="dress" d="M380.235 89.2078V92.872L383.616 94.2834C412.647 106.401 431.138 129.299 431.138 154.542C431.138 164.547 427.323 175.055 421.027 186.458C415.502 196.467 408.35 206.686 400.786 217.494C399.725 219.011 398.655 220.539 397.581 222.08C380.466 246.627 362.047 274.375 362.047 305.464C362.047 351.687 370.846 385.437 384.153 413.977C395.253 437.783 409.496 457.918 423.903 478.286C426.645 482.163 429.393 486.047 432.127 489.968C449.3 514.597 466.247 541.056 478.957 576.789C491.507 612.073 500.013 656.653 500.226 717.651C499.503 718.276 498.484 719.102 497.129 720.073C493.677 722.547 488.03 725.979 479.552 729.458C462.599 736.415 434.237 743.595 389.366 743.595C358.958 743.595 339.226 756.768 320.541 769.242L319.447 769.972C300.44 782.652 282.134 794.498 253.014 794.498C223.88 794.498 205.566 782.652 186.556 769.971L185.456 769.237C166.769 756.765 147.038 743.595 116.633 743.595C71.7629 743.595 43.4013 736.415 26.4482 729.458C17.9704 725.979 12.3236 722.547 8.8716 720.073C7.51674 719.102 6.49704 718.276 5.77459 717.651C5.98719 656.652 14.4929 612.072 27.0435 576.788C39.7539 541.055 56.7007 514.596 73.8735 489.967C76.6069 486.047 79.3549 482.162 82.0969 478.285C96.5046 457.917 110.747 437.782 121.847 413.976C135.154 385.436 143.953 351.686 143.953 305.462C143.953 274.374 125.534 246.626 108.419 222.078C107.344 220.537 106.275 219.009 105.213 217.492C97.6494 206.685 90.4974 196.466 84.9718 186.456C78.6764 175.053 74.8611 164.545 74.8611 154.541C74.8611 129.3 93.3807 106.4 122.386 94.2811L125.766 92.869V89.2062V5.5H140.216V81.8594V88.3863L146.648 87.2798C153.384 86.121 160.363 85.5016 167.536 85.5016C202.798 85.5016 232.969 100.196 248.608 121.134L253.015 127.033L257.421 121.133C273.059 100.193 303.2 85.5016 338.463 85.5016C345.635 85.5016 352.615 86.121 359.351 87.2798L365.783 88.3863V81.8594V5.5H380.235V89.2078Z" />
</svg>
  )
}

export default Dress