import './gift.css'

function Gift() {
  return (
<svg width="100" height="100" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="gift 1" clipPath="url(#clip0_9_96)">
<g id="longline">
<g id="Group">
<path id="Vector" d="M257.793 231.699C247.853 231.553 239.834 239.495 239.883 249.436L240.662 408.356C240.711 418.298 248.809 426.475 258.749 426.62C268.69 426.766 276.709 418.826 276.66 408.883L275.88 249.963C275.832 240.021 267.734 231.844 257.793 231.699Z" fill="#d2b869"/>
</g>
</g>
<g id="line2">
<g id="Group_2">
<path id="Vector_2" d="M362.227 363.425L318.399 406.829C311.473 413.687 311.585 425.03 318.649 432.162C325.713 439.295 337.053 439.517 343.979 432.659L387.807 389.255C394.733 382.396 394.621 371.054 387.557 363.921C380.493 356.789 369.152 356.567 362.227 363.425Z" fill="#d2b869"/>
</g>
</g>
<g id="line1">
<g id="Group_3">
<path id="Vector_3" d="M127.634 361.632C121.07 368.133 121.475 379.184 128.539 386.318L173.24 431.455C180.304 438.588 191.352 439.101 197.915 432.6C204.479 426.1 204.074 415.048 197.01 407.915L152.309 362.777C145.246 355.645 134.198 355.132 127.634 361.632Z" fill="#d2b869"/>
</g>
</g>
<g id="circle2">
<g id="Group_4">
<path id="Vector_4" d="M448.266 249.534C418.528 248.989 394.673 272.611 394.93 302.354C395.185 332.022 419.527 356.602 449.192 357.146C478.925 357.692 502.784 334.069 502.527 304.327C502.272 274.658 477.931 250.079 448.266 249.534ZM466.663 303.669C466.748 313.58 458.797 321.457 448.884 321.275C438.996 321.093 430.882 312.9 430.797 303.011C430.713 293.095 438.663 285.222 448.575 285.405C458.488 285.587 466.576 293.755 466.663 303.669Z" fill="#d2b869"/>
</g>
</g>
<g id="circle1">
<g id="Group_5">
<path id="Vector_5" d="M67.6115 247.683C37.9467 247.139 14.0193 270.833 14.2753 300.503C14.5312 330.24 38.7992 354.751 68.5383 355.296C98.2 355.841 122.13 332.146 121.874 302.475C121.618 272.737 97.3498 248.227 67.6115 247.683ZM86.0066 301.819C86.0917 311.708 78.1166 319.606 68.2281 319.426C58.3176 319.242 50.2278 311.075 50.1409 301.162C50.0564 291.246 58.0066 283.373 67.9194 283.555C77.8322 283.736 85.9205 291.904 86.0066 301.819Z" fill="#d2b869"/>
</g>
</g>
<g id="dot4">
<g id="Group_6">
<path id="Vector_6" d="M362.3 269.812C368.864 263.311 368.458 252.259 361.395 245.126C354.331 237.994 343.283 237.481 336.719 243.981C330.156 250.482 330.561 261.534 337.625 268.666C344.688 275.799 355.736 276.312 362.3 269.812Z" fill="#d2b869"/>
</g>
</g>
<g id="dot3">
<g id="Group_7">
<path id="Vector_7" d="M180.533 268.929C187.097 262.428 186.692 251.376 179.628 244.243C172.564 237.111 161.517 236.598 154.953 243.098C148.389 249.599 148.794 260.651 155.858 267.783C162.922 274.916 173.969 275.429 180.533 268.929Z" fill="#d2b869"/>
</g>
</g>
<g id="dot2">
<g id="Group_8">
<path id="Vector_8" d="M506.995 415.921C513.559 409.421 513.154 398.369 506.09 391.236C499.027 384.103 487.979 383.591 481.415 390.091C474.851 396.591 475.257 407.643 482.32 414.776C489.384 421.909 500.432 422.422 506.995 415.921Z" fill="#d2b869"/>
</g>
</g>
<g id="dot1">
<g id="Group_9">
<path id="Vector_9" d="M34.4152 413.633C40.9791 407.132 40.5739 396.08 33.5101 388.947C26.4463 381.815 15.3989 381.302 8.835 387.802C2.27107 394.303 2.67626 405.355 9.74003 412.487C16.8038 419.62 27.8512 420.133 34.4152 413.633Z" fill="#d2b869"/>
</g>
</g>
<g id="cross">
<g id="Group_10">
<g id="Group_11">
<path id="Vector_10" d="M208.313 97.8726C201.322 104.796 201.382 116.19 208.446 123.323L221.235 136.237C228.299 143.37 239.692 143.54 246.683 136.617C253.673 129.694 253.614 118.3 246.55 111.167L233.76 98.2519C226.697 91.1198 215.303 90.9504 208.313 97.8726Z" fill="#d2b869"/>
</g>
</g>
<g id="Group_12">
<g id="Group_13">
<path id="Vector_11" d="M272.262 162.446C265.271 169.369 265.331 180.764 272.394 187.897L285.184 200.812C292.248 207.944 303.641 208.115 310.632 201.192C317.623 194.269 317.563 182.874 310.5 175.741L297.709 162.826C290.646 155.694 279.252 155.524 272.262 162.446Z" fill="#d2b869"/>
</g>
</g>
<g id="Group_14">
<g id="Group_15">
<path id="Vector_12" d="M221.367 161.686L208.71 174.221C201.719 181.144 201.778 192.538 208.842 199.671C215.906 206.804 227.299 206.974 234.29 200.051L246.947 187.516C253.938 180.593 253.879 169.198 246.815 162.066C239.751 154.933 228.357 154.763 221.367 161.686Z" fill="#d2b869"/>
</g>
</g>
<g id="Group_16">
<g id="Group_17">
<path id="Vector_13" d="M284.653 99.0118L271.996 111.547C265.005 118.47 265.065 129.864 272.128 136.997C279.192 144.13 290.586 144.3 297.576 137.377L310.234 124.842C317.224 117.919 317.165 106.525 310.101 99.3918C303.037 92.259 291.643 92.0896 284.653 99.0118Z" fill="#d2b869"/>
</g>
</g>
</g>
<g id="box">
<path id="Vector_14" d="M84.0001 756.829L84.1365 551.13C84.1413 543.878 90.023 538.004 97.275 538.009L421.142 538.224C428.393 538.228 434.268 544.11 434.263 551.362L434.127 757.061" stroke="#d2b869" strokeWidth="50" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_15" d="M434.199 544.681V750.38C434.199 757.632 428.321 763.51 421.069 763.51H97.2023C89.9509 763.51 84.0725 757.632 84.0725 750.38V544.681" stroke="#d2b869" strokeWidth="50" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_16" d="M259.136 762.854V552.778" stroke="#d2b869" strokeWidth="50" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<g id="tapa">
<path id="Vector_17" d="M469.883 426.917H48.45C41.0218 426.917 35 432.938 35 440.367V525.55C35 532.979 41.0218 539 48.45 539H469.883C477.312 539 483.333 532.979 483.333 525.55V440.367C483.333 432.938 477.312 426.917 469.883 426.917Z" fill="white" stroke="#d2b869" strokeWidth="50" strokeLinecap="round" strokeLinejoin="round"/>
<g id="Group 1">
<path id="Vector_18" d="M259.167 426.244H158.292C143.429 426.244 129.174 420.34 118.664 409.83C108.154 399.32 102.25 385.066 102.25 370.203C102.25 355.339 108.154 341.085 118.664 330.575C129.174 320.065 143.429 314.161 158.292 314.161C236.75 314.161 259.167 426.244 259.167 426.244Z" stroke="#d2b869" strokeWidth="50" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_19" d="M259.167 426.244H360.041C374.904 426.244 389.158 420.34 399.67 409.83C410.179 399.32 416.083 385.066 416.083 370.203C416.083 355.339 410.179 341.085 399.67 330.575C389.158 320.065 374.904 314.161 360.041 314.161C281.583 314.161 259.167 426.244 259.167 426.244Z" stroke="#d2b869" strokeWidth="50" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
</g>
<defs>
<clipPath id="clip0_9_96">
<rect width="800" height="800" fill="white"/>
</clipPath>
</defs>
</svg>


    
  )
}

export default Gift