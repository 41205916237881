import styles from '../styles/Footer.module.css'

function Footer() {
  return (
    <div className={styles.container}>
      <p className={styles.title}>Desarrollado por Bryan Vislao</p>
      <p className={styles.text}><a href="mailto:bvislao95@gmail.com">bvislao95@gmail.com</a></p>
    </div>
  )
}

export default Footer