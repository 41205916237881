import styles from "../styles/Final.module.css";
import Gift from "../components/gift/Gift";
import { GiConfirmed, GiChecklist } from "react-icons/gi";
import { RiPlayList2Fill } from "react-icons/ri";
import { FcPlanner } from "react-icons/fc";

function Final() {
  return (
    <section className={styles.container}>
      <div className={styles.textContainer}>
        <br />
        <h2 className={styles.h2Final}>
          "Este evento está diseñado exclusivamente para una audiencia adulta,<br/>
          ¡prepárense para una experiencia única y llena de diversión!"
        </h2>
        <br />
        <h2 className={styles.h3Final}>30 de abril 2024</h2>
      </div>
      <div className={styles.gift}>
        <Gift />
      </div>
      <div className={styles.linksContainer}>
        <a
          className={styles.ahref}
          rel="noreferrer"
          target="_blank"
          href="https://bit.ly/WPConfirmarByG"
        >
          <p className={styles.link}>
            <FcPlanner /> Consultas a nuestra Wedding Planner Veronica.
          </p>
        </a>
        {/* <a
          className={styles.ahref}
          rel="noreferrer"
          target="_blank"
          href="https://bit.ly/ConfirmarAsistenciaByG"
        >
          <p className={styles.link}><GiConfirmed /> Confirmar Asistencia</p>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          className={styles.ahref}
          href="https://bit.ly/AgregaTuCancionByG"
        >
          <p className={styles.link}><RiPlayList2Fill /> Añade tu canción en nuestra playlist.</p>
        </a> */}
        <a
          className={styles.ahref}
          rel="noreferrer"
          target="_blank"
          href="https://bit.ly/ListaBodaByG"
        >
          <p className={styles.link}>
            <GiChecklist /> Nuestra lista de regalos
          </p>
        </a>
      </div>
    </section>
  );
}

export default Final;
