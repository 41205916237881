import { IoIosArrowDown } from "react-icons/io";
import styles from '../styles/FrontPage.module.css'

function FrontPage() {
  return (
    <section className={styles.presentation}>
    <div className={styles.presentationTitle}>
    <h1 className={styles.h1_super}>Save the Date</h1><br />
      <h1 className={styles.h1}>Bryan & Geraldine</h1>
      <br />
      <h2 className={styles.h3}><strong>30 de abril del 2024</strong></h2>
      <IoIosArrowDown className={styles.arrowIcon} />
    </div>
  </section>

  )
}

export default FrontPage