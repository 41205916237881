import './suit.css'

function Suit() {
  return (

<svg height="100" viewBox="0 0 590 800" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Group">
<g id="traje">
<path id="tie" d="M297.559 58.4222L295.937 61.6762L298.071 64.6202L328.877 107.128L294.966 297.205L261.047 107.131L291.901 64.6238L294.04 61.6761L292.412 58.4177L280.866 35.306H309.084L297.559 58.4222Z" stroke="black" strokeWidth="12"/>
<path id="suit" d="M7.22597 717.417L22.7235 453.52L54.2056 99.5004L201.906 37.3233L203.953 36.4616L204.945 34.4749L213.582 17.1885L225.514 64.8312L289.063 430.292L300.886 430.293L364.477 64.8545L376.377 17.2171L385.022 34.5022L386.014 36.487L388.06 37.3482L535.752 99.5338L567.284 453.521L582.782 717.417H530.62L473.316 371.6L461.599 371.035L440.147 451.49L439.758 452.953L440.108 454.425L502.59 717.013L331.123 791.543L300.821 642.36L289.061 642.359L258.744 791.543L87.3086 717.013L149.758 454.424L150.108 452.953L149.719 451.492L128.284 371.036L116.566 371.601L59.3692 717.417H7.22597ZM463.404 233.471L468.58 232.754V227.528V200.709V197.763L466.249 195.962L444.363 179.051L439.726 175.468L436.035 180.019L393.307 232.705L383.729 244.515L398.791 242.427L463.404 233.471Z" />
</g>
</g>
</svg>

  )
}

export default Suit